.list-grid {
    display: grid;
    grid-template-rows: repeat(3, 1fr);
    grid-template-columns: repeat(2, 1fr);
    grid-auto-flow: column;
    gap: 8px;
    grid-column-gap: 100px;

}
.list-grid-small {
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(2, 1fr);
    grid-auto-flow: column;
    gap: 8px;
    grid-column-gap: 100px;

}
.list-grid-answer-small {

    display: grid;
    place-items: center;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(2, 200px);
    grid-auto-flow: column;
    gap: 8px;
    grid-column-gap: 100px;

}
.list-grid-answer-big {

    display: grid;
    place-items: center;
    grid-template-columns: repeat(2, 200px);
    grid-template-rows: repeat(3, 1fr);
    grid-auto-flow: column;
    gap: 8px;
    grid-column-gap: 100px;
}

.list-item{ 
    min-width: 120px;

}

.list-item-answer {
    min-width: 100px;
}
.list-border {
    box-sizing: border-box;
    border: 1px solid rgba(178, 178, 178, 1);
}

.pair-input {
    border: none;
    outline: none;
}
