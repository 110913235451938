.modal-90w {
    max-width: 90%;
    display: flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  /* .modal-90w .modal-content {
    width: 100%;
    height: 100%;
    max-height: 100vh;
  }*/
  .modal-90w .modal-body {
    display: flex;
    flex-direction: column;
    padding: 30px;
    align-items: flex-start;
    justify-content: flex-start;
  } 

.modal-90w .modal-body label {
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 8px;
}