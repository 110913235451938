.form-container {
    width: 600px;
    /* border: 1px solid grey; */
    box-shadow:
  0 2.8px 2.2px rgba(0, 0, 0, 0.034),
  0 6.7px 5.3px rgba(0, 0, 0, 0.048),
  0 12.5px 10px rgba(0, 0, 0, 0.06),
  0 22.3px 17.9px rgba(0, 0, 0, 0.072),
  0 41.8px 33.4px rgba(0, 0, 0, 0.086),
  0 100px 80px rgba(0, 0, 0, 0.12)
;
  margin: 100px auto;
  background: rgb(255, 255, 255);
  border-radius: 10px;

  }
Form.Control {
  margin-bottom: '5px';
}


.form-control.is-invalid {
  border-color: red;
}
