.container-color {
    background-color: white;
}
.input {
    height: 80px;
    width: 100px;
    border: 0ch;
}
.correct {
    background-color: #29AE4E;
    /* height: 50px; */
}
.incorrect {
    background-color: #CD1D1D;
    /* height: 50px; */
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type=number] {
    -moz-appearance: textfield;
}