.money-table1 tr td {
  border: 1px solid black;
  text-align: center;
  font-size: 18px;


  height: 50px;
  font-weight: 600;
}

.money-table1 tr,td {
  width: 30px;
  max-width: 50px;  
}

.money-table1 tr td img {
  max-width: 50px;
}


.money-table1 input {
  max-width: inherit;
}
.money-table1 {
  height: 5rem;
  min-width: 80vw;
}

.answer {
  display: flex;
  justify-content: space-evenly;
} 

.money-table tr td {
  border: 1px solid black;
  text-align: center;
  font-size: 18px;
  width: 50px;
  height: 50px;
  font-weight: 600;
}

.money-table tr td img {
  width: 50px;
}

.money-table {
  width: 50%;
  height: 5rem;
} 

.answer {
  display: flex;
  justify-content: space-evenly;
}
