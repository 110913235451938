
  .custom-container {
    border: 2px solid #ccc;
    border-radius: 10px;
    padding-top: 20px;
    margin-bottom: 20px;
    position: relative;
    height: 450px;
  }
  
  .custom-title {
    position: absolute;
    top: -13px;
    left: 25px;
    background-color: #f2f1f1;
    padding: 0 5px;
  }
  
  .custom-content {
    /* margin-top: 20px; */
    max-height: 420px;
    overflow-y:visible;
    overflow-x: hidden;
  }
  /* ::-webkit-scrollbar {display:none;} */