.line-container {
    position: relative;
    min-height: 25px;
    min-width: 80vw;
    max-width: 80vw;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.line {
    position: absolute;
    left: 0;
    top: 20%;
    transform: translate(0, -20%);
    min-height: 2px;
    max-height: 2px;
    min-width: 100%;
    background-color: black;;
}

.line-bar {
    min-height: 25px;
    max-height: 25px;
    min-width: 2px;
    max-width: 2px;
    background-color: black;
    position: relative;
}

.green-circle {
    height: 15px;
    width: 15px;
    border-radius: 50%;
    background-color: green;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.para-text {
    max-width: 80%;
    text-align: center;
}

/* .hide {
    background-color: blanchedalmond;
} */
.number-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}