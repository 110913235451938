.word{
    font-weight: bold;
}

.class-hover:hover {
    font-weight: 500;
    text-decoration: underline;
    cursor: pointer;
}

::-webkit-scrollbar {
    /* display: none; */
}