/* .container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
   */

   input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}

.correct {
    background-color: #29AE4E;
    /* height: 50px; */
}
.incorrect {
    background-color: #CD1D1D;
    /* height: 50px; */
}
