.name{
    margin-top:5%;
    margin-bottom: 10%;
}
.container-fluid {
    /* width: 95%; */
    border-radius: 26px;
}

.homework{
    margin-top: 10%;
    /* margin-right: 6rem; */
    margin-bottom: 5%;
}
.score{
    margin-top: 5%;
    text-align: end;
    margin-bottom: 10%;

}
.btn3d {
    
	color: #666666;
    width: 200px;
    box-shadow: 0 0 0 1px #cecece inset, 0 0 0 2px rgba(255, 255, 255, 0.10) inset, 0 2px 0 0 #BEBEBE, 0 2px 8px 1px rgba(0, 0, 0, .2);
}
.circle {
    border-radius: 50%;
    width: 100px;
    height: 100px;
    padding: 10px;
    background: #fff;
    border: 3px solid #000;
    color: #000;
    text-align: center;
    font: 32px Arial, sans-serif;
  }