.page{
    height: '200px';
}
.detail{
    width: 100%;

}
.name{
    width: 50%;
}
.photo{
    width: 25%;
    /* margin-bottom: 48px; */
    
   
}
.photo div img{
    height: 135px;
    width: 135px;
    border-radius: 100%;
    border: 2px solid black;
    text-align:center;
}

.marks{
    width: 25%;
    align-items: center;
}
.score{
    height: 135px;
    width: 135px;
    border-radius: 100%;
    border: 2px solid black;
    text-align:center; 
    font-weight: 600;
    font-size:xx-large;
}
/* .score p {
    font-weight: 500;
    font-size:0.5cm;
    margin-top: 0ch;
    margin-bottom: 0ch;
} */