.grids {
  display: grid;
  grid-template-columns: auto auto auto auto;
  column-gap: -13%;
  row-gap: 10%;
  column-gap: 5%;
  margin-bottom: 5%;
  /* width: 500px; */
}

.answer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1%;
  margin-top: 3%;
}

.answer p {
  font-size: 20px;
  margin: 0;
}

.location-4-answer {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex-wrap: wrap;
}
